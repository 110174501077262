import React from 'react'

const Snippet = (props) => (
  <div>
    <hr size="1" />
    <h2>Credits</h2>
    <p>Newsletter subscription icon</p>
    <ul>
      <li>
        Binoculars by Ronald Vermeijs from{' '}
        <a
          href="https://thenounproject.com/term/binoculars/14438/"
          target="_blank"
          noopener
          nofollow
        >
          the Noun Project
        </a>
      </li>
    </ul>
    <p>404 icon</p>
    <ul>
      <li>
        lost by Graphic Enginer, ID{' '}
        <a
          href="https://thenounproject.com/term/lost/2503787/"
          target="_blank"
          noopener
          nofollow
        >
          the Noun Project
        </a>
      </li>
    </ul>
    <p>Pricing icons</p>

    <ul>
      <li>
        Fast Email by Creative Stall from{' '}
        <a
          href="https://thenounproject.com/creativestall/collection/1400-web-and-seo-flat-line-icons/?i=986376"
          target="_blank"
          noopener
          nofollow
        >
          the Noun Project
        </a>
      </li>
      <li>
        responsive by Creative Stall{' '}
        <a
          href="https://thenounproject.com/term/responsive/986367/"
          target="_blank"
          noopener
          nofollow
        >
          the Noun Project
        </a>
      </li>
      <li>
        Clean Code by Creative Stall{' '}
        <a
          href="https://thenounproject.com/term/clean-code/996205/"
          target="_blank"
          noopener
          nofollow
        >
          the Noun Project
        </a>
      </li>
      <li>
        Website ranking by Ayub Irawan from the Noun Project{' '}
        <a
          href="https://thenounproject.com/term/website-ranking/1167777/"
          target="_blank"
          noopener
          nofollow
        >
          the Noun Project
        </a>
      </li>
    </ul>
  </div>
)

export default Snippet
