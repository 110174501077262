/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

//Bootstrap

// import 'jquery/dist/jquery.min.js'
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import React from 'react'
import parse from 'url-parse'

import NavStreamlined from './src/components/nav/navStreamlined'

//Redux
import wrapWithProvider from './wrap-with-provider'
export const wrapRootElement = wrapWithProvider

const ReactDOM = require('react-dom')

const url = parse(window.location.href, true)

// if (
//   url.pathname &&
//   (url.pathname.includes('profile') ||
//     url.pathname.includes('tag') ||
//     url.pathname.includes('template'))
// ) {
require('./src/scss/main.scss')
require('jquery/dist/jquery.min.js')
require('popper.js/dist/popper.min')
require('bootstrap/dist/js/bootstrap.min.js')
// }
// export const replaceHydrateFunction = () => {
//   const url = parse(window.location.href, true)
//   console.log('**hydrate - url')
//   console.log(url)

//   return (element, container, callback) => {
//     console.log('rendering!')
//     console.log('hydrate - element')
//     console.log(element)
//     console.log('hydrate - container')
//     console.log(container)
//     if (url.pathname && !url.pathname.includes('creative')) {
//       console.log('YES HYDRATE!')
//       ReactDOM.render(element, container, callback)
//     } else {
//       console.log('NO HYDRATE!')
//       //   ReactDOM.render(
//       //     React.renderToString(<NavStreamlined />),
//       //     document.getElementById('___gatsby'),
//       //     callback
//       //   )
//       // ReactDOM.hydrate(
//       //   <NavStreamlined />,
//       //   document.getElementById('navStreamlined-container'),
//       //   callback
//       // )
//       ReactDOM.hydrate(element, container, callback)
//     }
//   }
// }

// export const onClientEntry = () => {
//   // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//   if (typeof window.IntersectionObserver === `undefined`) {
//     import(`intersection-observer`)
//     console.log(`# IntersectionObserver is polyfilled!`)
//   }
// }
